import { AwardCard } from "../../components";
import React from "react";
import "./awards.css"

const Awards = () => {
    return(
        <div className="Portafolio__Awards" id="awards">
            <div className="glitch_section" data-text="Awards">Awards</div>
            <div className="Portafolio__Awards-cards">
                <AwardCard title="Certificate of Participation" year="2023" conference="International Workshop on Advanced Image Technology (IWAIT)" location="Jeju, South Korea" />
                <AwardCard title="Young Researchers Encouragement Award for the Best Project of the Conference" year="2023" conference="The 5th ASEAN UEC Seminar on Informatics and Engineering" location="Bangkok, Thailand" />
                <AwardCard title="Certificate of Congratulations and Commendation for the Best Student of the Generation" year="2023" conference="The University of Electro-Communications (電気通信大学)" location="Tokyo, Japan" />
            </div>
        </div>
    )
}

export default Awards