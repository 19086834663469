import { ProjectCard } from "../../components";
import React from "react";
import "./projects.css";

const Projects = () => {

    const p1_tecs = ["HTML", "CSS", "JavaScript"];
    const p2_tecs = ["HTML", "CSS", "JavaScript", "PHP"];
    const p3_tecs = ["HTML", "CSS", "ReactJS", "JavaScript", "PHP"];

    return(
        <div className="Portafolio__Projects" id="projects">
            <div className="glitch_section" data-text="Projects">Projects</div>
            <div className="Portafolio__Projects-cards">
                <ProjectCard title="AVAG | IC" img="avag" desc="Mexican construction company that emphasizes responsibility, honesty, and transparency, ensuring the success of its projects." url="https://www.avagic.com/" tec = {p2_tecs} color = "#000000"/>
                <ProjectCard title="Satvs" img="satvs" desc="A residential project covering 621m², divided into sections with high design standards, set in a warm environment surrounded by rich biodiversity." url="https://luisestebanacevedobringas.github.io/SATUS-CSL/" tec = {p1_tecs} color = "#5E624E"/>
                <ProjectCard title="Jagaimo Club" img="jagaimo" desc="The JC Potato Club was founded in 1954, and in 1958, the JC introduced a regulation on subcommittee activities, officially recognizing the Potato Club as an official subcommittee." url="http://www.jc-jagaimoclub.jp/index.html" tec = {p1_tecs} color = "#33662F"/>
                <ProjectCard title="WILJO Vending" img="wiljo" desc="WILJO is a full-service vending company that supplies video games, pool tables, jukeboxes, ATMs, and other machinery to bars and restaurants." url="https://wiljovending.com/" tec = {p3_tecs} color = "#1f3689"/>
            </div>
        </div>
    )
}

export default Projects;
