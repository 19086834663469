import { Social } from "../../components";
import React from "react";
import "./contact.css"

const Contact = () => {
    return(
        <div className="Portafolio__Contact" id="contact">
            <div className="glitch_section" data-text="Contact">Contact</div>
            <div className="Portafolio__Contact-social__warp">
                <Social name="github" url="https://github.com/LuisEstebanAcevedoBringas"/>
                <Social name="linkedin" url="https://www.linkedin.com/in/luis-esteban-acevedo-bringas/"/>
                <Social name="instagram" url="https://www.instagram.com/bringas.el.pelon/"/>
            </div>
            <p className="email">
                <a href="mailto:luisbringas@luisbringas.com">luisbringas@luisbringas.com</a>
            </p>
        </div>
    )
}

export default Contact

