import React from "react";
import "./experience.css";

const Experience = () => {
    return(
		<div className="Portafolio__Experience" id="experience">
            <div className="glitch_section" data-text="Work Experience">Work Experience</div>
			<div className="Portafolio__Experience-container">
				{/* <div class="Portafolio__Experience-container__box">
					<h4>2024 - Now</h4>
					<h3>Running with Scissors - IA Art Designer</h3>
					<p> - </p>
				</div> */}
				<div className="Portafolio__Experience-container__box">
					<h4>2020 - Now</h4>
					<h3>Freelancer - Technical Designer and Programmer</h3>
					<p> - Design, creation, and training of AI models.</p>
					<p> - Interface design, development and optimization of code.</p>
					<p> - Techniques and protocols of secure development and coding.</p>
					<h3>Key Collaborations:</h3>
					<p> - Collaborated with the construction company, <strong>AVAGIC</strong>, innovating weband interface design for internal projects and web management.</p>
					<p> - Worked with video game developer <strong>Running with Scissors</strong> in the design and training of AI models for video game development.</p>
					<p> - Worked with the American vending machine distributor <strong>WILJO</strong> on advanced interface design projects, improving user experience.</p>
				</div>
			</div>
		</div>
    )
}

export default Experience;
