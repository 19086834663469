import projectsMapping from "../../assets/projects/projectsMapping";
import Button from "../button/Button";
import "./projectCard.css";
import React from "react";

const ProjectCard = ({ title, img, desc, url, tec, color }) => {
    const imageUrl = projectsMapping[img];

    const cardLeftStyle = {
        backgroundColor: color,
    };

    return (
        <div className="card">
            <div className="card_left" style={cardLeftStyle}>
                <img src={imageUrl} alt={title} />
            </div>
            <div className="card_right">
                <h3>{title}</h3>
                <div className="card_right__details">
                    <ul>
                        {tec.map((tech, index) => (
                            <li key={index}>{tech}</li>
                        ))}
                    </ul>
                </div>
                <div className="card_right__review">
                    <p>{desc}</p>
                </div>
                <div className="btn_cont">
                    <Button text="Page" href={url}/>
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;
