import React from "react";
import "./skill.css";

function formatName(name) {
    const specialNames = {
        js: 'JavaScript',
        ts: 'TypeScript',
        cplus: 'C++',
        csharp: 'C#',
        php: 'PHP',
        html: 'HTML',
        css: 'CSS',
        react: 'ReactJS',
        dj: 'Django',
        mysql: 'MySQL',
        mongo: 'MongoDB',
        nodejs: 'NodeJS',
        pytorch: 'PyTorch',
        visual: 'VSCode',
        npm: 'npm',
        gitlab: 'GitLab',
        github: 'GitHub',
        opencv: 'OpenCV'
    };

    // Return the special name if it exists, otherwise capitalize the first letter of each word
    return specialNames[name] || name.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const Skill = ({ img, name }) => {
    return(
        <div className="singleSkill">
            <img src={img} alt={name} />
            <h2>{formatName(name)}</h2>
        </div>
    );
}

export default Skill;