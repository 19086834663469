import React from "react";
import "./awardCard.css"

const AwardCard = ({title, year, conference, location}) => {
    return(
        <div className="Portafolio__acard">
            <h3 className="Portafolio__acard-title">{title}</h3>
            <h4 className="Portafolio__acard-conference">{conference}</h4>
            <h4 className="Portafolio__acard-location">{location}</h4>
            <h4 className="Portafolio__acard-year">{year}</h4>
        </div>
    )
}

export default AwardCard