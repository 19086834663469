import publicationMapping from "../../assets/publications/publicationsMapping";
import Button from "../button/Button";
import React from "react";
import "./paperCard.css"

const PaperCard = ({ title, img, conf, abstract, codeurl, paperurl, confurl }) => {
    const imageUrl = publicationMapping[img];

    return(
        <div className="Portafolio__ppcard">
            {/* <h3 className="Portafolio__pcard-title">{title}</h3> */}
            <img src={imageUrl} alt={title} />
            <div className="Protafolio__ppcard-wrap">
                <h4 className="Portafolio__pcard-title">{conf}</h4>
            </div>
            <div className="Portafolio__pcard-btnBox">
                <Button className="Portafolio__pcard-btn" text="Paper" href={paperurl}/>
                <Button className="Portafolio__pcard-btn" text=" Code" href={codeurl}/>
            </div>
        </div>
    )
}

export default PaperCard
