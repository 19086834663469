import React, { useState, useEffect, useRef } from "react";
import "./navbar.css";
import iconsMapping from "../../assets/icons/iconsMapping";

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [navbarBG, setNavbarBG] = useState(false);
    const navRef = useRef(null);

    useEffect(() => {
        const changeNavbarBG = () => {
            if (window.scrollY > 1) {
                setNavbarBG(true);
            } else {
                setNavbarBG(false);
            }
        };

        window.addEventListener('scroll', changeNavbarBG);
        return () => window.removeEventListener('scroll', changeNavbarBG);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setToggleMenu(false);
            }
        };

        if (toggleMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [toggleMenu]);

    return (
        <header className={`Header ${navbarBG ? "Navbar_Color_Scroll" : ""}`}>
            <nav className="Navbar" ref={navRef}>
                <a href="#home" className="Logo">LB</a>
                <div className={`nav_links ${toggleMenu ? "active" : ""}`} id="nav_links">
                    <img src={iconsMapping.close} alt="Close" onClick={() => setToggleMenu(false)} />
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#experience">Experience</a></li>
                        <li><a href="#skills">Skills</a></li>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href="#publications">Publications</a></li>
                        <li><a href="#awards">Awards</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
                <img src={iconsMapping.menu} alt="Menu" onClick={() => setToggleMenu(true)} className="menu-icon" />
            </nav>
        </header>
    );
}

export default Navbar;