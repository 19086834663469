import {Header, Projects, Publications, Skills, Awards, Contact, Experience} from "./containers"
import {Navbar} from "./components"
import React from 'react';
import "./App.css"

const App = () => {
  return (
    <div className='App'>
      <Navbar />
      <Header />
      <Experience />
      <Skills />
      <Projects />
      <Publications />
      <Awards />
      <Contact />
    </div>
  )
}

export default App;
